// components/UrlInput.js
import React from 'react';
import classNames from 'classnames';

function UrlInput({ inputUrl, handleInputChange, handleGoClick, isUrlEntered }) {
    return (
        <div className="w-full flex font-normal">
            <input
                type="text"
                className="flex-grow h-10 p-2 mr-2 border border-gray-300 rounded"
                placeholder="Enter a website: e.g. www.google.com"
                onChange={handleInputChange}
                value={inputUrl}
            />
            <button
                className={classNames('h-10 px-4 text-white bg-[#6326FC] rounded', {
                    'opacity-50 cursor-not-allowed': !isUrlEntered,
                })}
                onClick={handleGoClick}
                disabled={!isUrlEntered}
            >
                Go
            </button>
        </div>
    );
}

export default UrlInput;