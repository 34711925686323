import React, { useState, useEffect } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

function ChangeViewModal({ newImageUrl, oldImageUrl, onClose }) {
    const [modalDimensions, setModalDimensions] = useState({ width: '100%', height: 'auto' });
    const [position, setPosition] = useState(50); // Default to 50% for center

    useEffect(() => {
        const loadImage = async (src) => {
            return new Promise((resolve) => {
                const img = new Image();
                img.onload = () => resolve({ width: img.width, height: img.height });
                img.src = src;
            });
        };

        const setModalSize = async () => {
            const oldImage = await loadImage(oldImageUrl);
            const newImage = await loadImage(newImageUrl);

            const width = Math.max(oldImage.width, newImage.width, 800);
            const height = (oldImage.height / oldImage.width) * width;
            setModalDimensions({ width, height });
        };

        setModalSize();
    }, [oldImageUrl, newImageUrl]);

    // Update slider position based on mouse movement
    const handleMouseMove = (event) => {
        const modalRect = event.currentTarget.getBoundingClientRect();
        const xPos = ((event.clientX - modalRect.left) / modalRect.width) * 100;
        setPosition(xPos);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div
                className="relative bg-white rounded-lg overflow-hidden shadow-lg"
                style={{width: modalDimensions.width, height: modalDimensions.height}}
            >
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-gray-700 hover:text-black z-50"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </button>
                <div
                    className="relative flex items-center justify-center w-full h-full p-4 overflow-hidden"
                    onMouseMove={handleMouseMove}
                >
                    <style>
                        {`.__rcs-handle-button { display: none !important; }`}
                    </style>
                    <ReactCompareSlider
                        itemOne={<ReactCompareSliderImage src={oldImageUrl} alt="Old screenshot"/>}
                        itemTwo={<ReactCompareSliderImage src={newImageUrl} alt="New screenshot with overlay"/>}
                        position={position}
                        className="w-full h-full object-contain"
                    />
                </div>
            </div>
        </div>
    );
}

export default ChangeViewModal;
