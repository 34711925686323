// CreateJobModal.js
import React, { useState, useRef, useEffect } from 'react';
import UrlInput from './UrlInput';
import ScreenshotDisplay from './ScreenshotDisplay';
import EmailFrequencyForm from './EmailFrequencyForm';
import { supabase } from '../supabaseClient';

function CreateJobModal({ user, currentPlan,onClose }) {
    const [inputUrl, setInputUrl] = useState('');
    const [screenshotUrl, setScreenshotUrl] = useState('');
    const [apiResponseReceived, setApiResponseReceived] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [frequency, setFrequency] = useState('');
    const [isListboxVisible, setIsListboxVisible] = useState(false);
    const listboxRef = useRef(null);

    const frequencyOptions = {
        FREE: ["Every fifteen minutes", "Every half hour", "Every hour", "Every 6 hours", "Daily", "Weekly", "Monthly"],
        STANDARD: ["Every five minutes", "Every fifteen minutes", "Every half hour", "Every hour", "Every 6 hours", "Daily", "Weekly", "Monthly"],
        PRO: ["Every two minutes", "Every five minutes", "Every fifteen minutes", "Every half hour", "Every hour", "Every 6 hours", "Daily", "Weekly", "Monthly"],
        ENTERPRISE: ["Every two minutes", "Every five minutes", "Every fifteen minutes", "Every half hour", "Every hour", "Every 6 hours", "Daily", "Weekly", "Monthly"],
    };

    const allFrequencies = [
        "Every two minutes", "Every five minutes", "Every fifteen minutes",
        "Every half hour", "Every hour", "Every 6 hours", "Daily", "Weekly", "Monthly"
    ];

    const allowedFrequencies = frequencyOptions[currentPlan] || [];

    const [crop, setCrop] = useState({
        unit: 'px',
        width: 200,
        height: 200,
        x: 200,
        y: 200,
        aspect: 1
    });
    const handleClickOutside = (event) => {
        if (listboxRef.current && !listboxRef.current.contains(event.target)) {
            setIsListboxVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleListboxVisibility = () => {
        setIsListboxVisible(!isListboxVisible);
    };

    const isEmailEntered = email.trim() !== '';
    const isUrlEntered = inputUrl.trim() !== '';

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const [originalDimensions, setOriginalDimensions] = useState({ width: 0, height: 0 });
    const [displayedImageDimensions, setDisplayedImageDimensions] = useState({ width: 0, height: 0 });

    const handleImageLoad = (event) => {
        setOriginalDimensions({
            width: event.target.naturalWidth,
            height: event.target.naturalHeight,
        });

        setDisplayedImageDimensions({
            width: event.target.offsetWidth,
            height: event.target.offsetHeight,
        });
    };

    const handleGoClick = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/take-screenshot`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ url: inputUrl }) // Send URL in the JSON body
            });

            if (response.ok) {
                const imageBlob = await response.blob();
                const imageObjectURL = URL.createObjectURL(imageBlob);
                setScreenshotUrl(imageObjectURL);
                setApiResponseReceived(true);
            } else {
                throw new Error('Screenshot API responded with an error.');
            }
        } catch (error) {
            console.error('Error taking screenshot:', error);
            setApiResponseReceived(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleWatchButtonClick = async () => {
        if (originalDimensions.width === 0 || displayedImageDimensions.width === 0) {
            console.error("Image dimensions not set yet");
            return;
        }

        const scaleX = originalDimensions.width / displayedImageDimensions.width;
        const scaleY = originalDimensions.height / displayedImageDimensions.height;

        const adjustedCrop = {
            x: Math.round(crop.x * scaleX),
            y: Math.round(crop.y * scaleY),
            width: Math.round(crop.width * scaleX),
            height: Math.round(crop.height * scaleY)
        };

        // Mapping of intervals in minutes
        const intervalMapping = {
            "Every two minutes": 0.1,
            "Every five minutes": 5,
            "Every fifteen minutes": 15,
            "Every half hour": 30,
            "Every hour": 60,
            "Every 6 hours": 360,
            "Daily": 1440,
            "Weekly": 10080,
            "Monthly": 43200
        };

        const intervalInMinutes = intervalMapping[frequency];
        if (!intervalInMinutes) {
            console.error('Invalid frequency selected');
            return;
        }

        // Get the current timestamp
        const currentTime = new Date();
        // Calculate next_run_at by adding the interval in minutes
        const nextRunAt = new Date(currentTime.getTime() + intervalInMinutes * 60 * 1000);

        const requestBody = {
            url: inputUrl,
            contact_email: email,
            frequency: `${intervalInMinutes} minutes`, // Save a string that can be parsed as INTERVAL in the DB
            x: adjustedCrop.x,
            y: adjustedCrop.y,
            width: adjustedCrop.width,
            height: adjustedCrop.height,
            user_id: user.id,
            is_active: true,
            created_at: currentTime.toISOString(), // Save as timestamp in ISO format
            next_run_at: nextRunAt.toISOString() // Save as timestamp in ISO format
        };

        try {
            const { error } = await supabase
                .from('monitoring_jobs')
                .insert([requestBody]);

            if (error) {
                throw new Error(`Error inserting job: ${error.message}`);
            }

            alert("Monitoring job created successfully!");
            onClose(); // Close the modal after the job is created
        } catch (error) {
            console.error('Error creating monitoring job:', error);
        }
    };

    const handleInputChange = (event) => {
        setInputUrl(event.target.value);
        setApiResponseReceived(false);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-2xl shadow-2xl w-full max-w-2xl relative">

                {/* Modal Header */}
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-3xl font-extrabold text-[#6326FC]">New Monitoring Job</h2>
                    <button
                        onClick={onClose}
                        className="bg-gray-200 text-gray-700 rounded-lg px-5 py-2 hover:bg-gray-300 transition"
                    >
                        Cancel
                    </button>
                </div>

                {/* URL Input Section */}
                <UrlInput
                    inputUrl={inputUrl}
                    handleInputChange={handleInputChange}
                    handleGoClick={handleGoClick}
                    isUrlEntered={isUrlEntered}
                />

                {/* Screenshot Display */}
                <div className="my-4">
                    <ScreenshotDisplay
                        crop={crop}
                        setCrop={setCrop}
                        screenshotUrl={screenshotUrl}
                        isLoading={isLoading}
                        apiResponseReceived={apiResponseReceived}
                        handleImageLoad={handleImageLoad}
                    />
                </div>

                {/* Email and Frequency Form */}
                <EmailFrequencyForm
                    email={email}
                    handleEmailChange={handleEmailChange}
                    handleWatchButtonClick={handleWatchButtonClick}
                    isEmailEntered={isEmailEntered}
                    frequency={frequency}
                    frequencies={allFrequencies}
                    allowedFrequencies={allowedFrequencies}
                    toggleListboxVisibility={toggleListboxVisibility}
                    isListboxVisible={isListboxVisible}
                    setFrequency={setFrequency}
                    listboxRef={listboxRef}
                />
            </div>
        </div>
    );
}

export default CreateJobModal;
