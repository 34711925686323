// ConfirmationModal.js

import React from 'react';
import { supabase } from '../supabaseClient';

function ConfirmationModal({ isOpen, jobId, onCancel, message, onConfirmSuccess }) {
    if (!isOpen) return null;

    const handleConfirm = async () => {
        try {
            // Database call to update the job directly
            const { error } = await supabase
                .from('monitoring_jobs')
                .update({ is_active: false })
                .eq('id', jobId);

            if (error) {
                throw new Error(`Error deactivating job: ${error.message}`);
            }

            // Call the success callback to refresh or update the parent component's state
            if (onConfirmSuccess) onConfirmSuccess(jobId);
        } catch (error) {
            console.error('Error deactivating job:', error);
        }
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={onCancel}>
            <div
                className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center relative"
                onClick={(e) => e.stopPropagation()} // Prevents click from closing modal when inside
            >
                {/* Close button in the top-right corner */}
                <button
                    onClick={onCancel}
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
                    aria-label="Close"
                >
                    ✕
                </button>
                <p className="text-lg font-semibold mb-4">{message}</p>
                <div className="flex justify-center space-x-4">
                    <button
                        onClick={handleConfirm}
                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                        Yes
                    </button>
                    <button
                        onClick={onCancel}
                        className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                    >
                        No
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmationModal;
