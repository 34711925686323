import React from 'react';

function PricingPage() {
    const pricingOptions = [
        {
            title: 'Free',
            price: '$0',
            features: [
                '150 checks per month',
                '5 pages/jobs',
                'Hourly frequency',
            ],
            highlight: false,
        },
        {
            title: 'Standard',
            price: '$10/month',
            features: [
                '1,000 checks per month',
                '25 pages/jobs',
                '15 min frequency',
            ],
            highlight: true,
        },
        {
            title: 'Pro',
            price: '$25/month',
            features: [
                '5,000 checks per month',
                '100 pages/jobs',
                '5 min frequency',
            ],
            highlight: false,
        },
        {
            title: 'Enterprise',
            price: '$50/month',
            features: [
                '10,000 checks per month',
                '200 pages/jobs',
                '2 min frequency',
            ],
            highlight: false,
        },
    ];

    return (
        <div className="min-h-screen flex flex-col items-center bg-gray-100 py-12 px-4">
            <h1 className="text-4xl font-extrabold text-[#6326FC] mb-6 text-center">Pricing Plans</h1>
            <p className="text-lg text-gray-700 text-center max-w-2xl mb-12">
                Choose the best plan that fits your needs. Our flexible pricing options are designed to help you monitor efficiently.
            </p>
            <div className="flex flex-wrap justify-center gap-8">
                {pricingOptions.map((option, index) => (
                    <div
                        key={index}
                        className={`w-full sm:w-1/2 lg:w-1/4 p-6 rounded-lg shadow-lg bg-white ${
                            option.highlight ? 'border-2 border-[#6326FC]' : 'border border-gray-300'
                        }`}
                    >
                        <h2 className={`text-2xl font-bold ${option.highlight ? 'text-[#6326FC]' : 'text-gray-800'} mb-2 text-center`}>
                            {option.title}
                        </h2>
                        <p className="text-3xl font-extrabold text-gray-800 text-center mb-4">{option.price}</p>
                        <ul className="space-y-3 mb-6">
                            {option.features.map((feature, idx) => (
                                <li key={idx} className="text-gray-600 text-center">
                                    {feature}
                                </li>
                            ))}
                        </ul>
                        <div className="flex justify-center">
                            <button
                                className={`px-6 py-2 text-sm font-semibold rounded-lg shadow-lg transition-transform duration-200 ${
                                    option.highlight
                                        ? 'bg-gradient-to-r from-[#6326FC] to-[#ff784b] text-white hover:scale-105'
                                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                                }`}
                            >
                                {option.title === 'Free' ? 'Get Started' : 'Choose Plan'}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PricingPage;
