import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

function EmailFrequencyForm({
                                email,
                                handleEmailChange,
                                handleWatchButtonClick,
                                isEmailEntered,
                                frequency,
                                frequencies,
                                allowedFrequencies,
                                toggleListboxVisibility,
                                isListboxVisible,
                                setFrequency,
                                listboxRef,
                                isLandingPage,
                            }) {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        if (isLandingPage) {
            navigate('/signup');  // Redirect to signup on landing page
        } else {
            handleWatchButtonClick();  // Otherwise, create the job
        }
    };

    return (
        <div className="w-full mx-auto mt-5 mb-5 flex font-normal">
            <input
                type="email"
                className="flex-grow h-10 p-2 mr-2 border border-gray-300 rounded"
                placeholder="Enter your email"
                onChange={handleEmailChange}
                value={email}
            />

            {/* Button Container */}
            <div className="flex items-center space-x-2">
                <button
                    className={classNames(
                        'bg-gradient-to-r from-[#6326FC] to-[#ff784b] text-white font-semibold rounded-lg px-5 py-2 shadow-lg hover:scale-105 transition-transform duration-200',
                        {
                            'opacity-50 cursor-not-allowed': !isEmailEntered,
                        }
                    )}
                    style={{ height: '40px' }} // Fix the button height
                    onClick={handleButtonClick}
                    disabled={!isEmailEntered}
                >
                    {isLandingPage ? "Start Free Monitoring" : "Create Job"}
                </button>

                {/* Frequency Dropdown Container */}
                <div className="relative inline-block">
                    <button
                        className={classNames('h-10 px-4 bg-white border border-gray-300 rounded', {
                            'opacity-50 cursor-not-allowed': !isEmailEntered,
                        })}
                        onClick={toggleListboxVisibility}
                        disabled={!isEmailEntered}
                        style={{ height: '40px' }} // Fix the dropdown button height
                    >
                        {frequency || 'Select Frequency'} ⌄
                    </button>

                    {/* Dropdown List */}
                    {isListboxVisible && (
                        <ul
                            className="absolute left-0 mt-1 list-none p-0 border border-gray-300 rounded max-h-52 overflow-y-auto w-40 bg-white"
                            ref={listboxRef}
                            style={{ zIndex: 10 }} // Ensure the dropdown is on top
                        >
                            {frequencies.map(frequencyOption => {
                                const isAllowed = allowedFrequencies.includes(frequencyOption);
                                return (
                                    <li
                                        key={frequencyOption}
                                        onClick={() => {
                                            if (isAllowed) {
                                                setFrequency(frequencyOption);
                                                toggleListboxVisibility();
                                            }
                                        }}
                                        className={classNames('p-2 cursor-pointer', {
                                            'bg-gray-200': frequency === frequencyOption,
                                            'text-gray-500 opacity-50 cursor-not-allowed': !isAllowed, // Style unavailable options
                                        })}
                                    >
                                        {frequencyOption}
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
}

export default EmailFrequencyForm;
