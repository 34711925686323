// UseCases.js
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faDollarSign, faChartLine, faBriefcase, faGavel, faNewspaper, faHome } from '@fortawesome/free-solid-svg-icons';

const useCases = [
    { title: 'Stock Alerts', description: 'Stay updated when high-demand items come back in stock or inventory changes.', icon: faBox },
    { title: 'Price Change Alerts', description: 'Monitor product prices across e-commerce sites to grab the best deals or track competitor pricing.', icon: faDollarSign },
    { title: 'Competitor Monitoring', description: 'Keep an eye on competitor sites for changes in products, services, or pricing updates.', icon: faChartLine },
    { title: 'Job Posting Updates', description: 'Get notified when new job postings appear on company career pages or job boards.', icon: faBriefcase },
    { title: 'Government or Regulatory Updates', description: 'Monitor government sites for policy or regulatory updates relevant to your industry.', icon: faGavel },
    { title: 'Content Monitoring for News and Publications', description: 'Track changes in content on news and publication sites to stay informed.', icon: faNewspaper },
    { title: 'Real Estate and Rental Listings Monitoring', description: 'Be alerted when new properties hit the market or rental listings change.', icon: faHome }
];

function UseCases() {
    return (
        <section
            className="relative mt-20 py-16 max-w-[90vw] overflow-hidden ">
            <div className="max-w-6xl mx-auto text-center mb-10">
                <h2 className="text-4xl font-bold text-white">Popular Use Cases</h2>
            </div>
            <div className="relative overflow-hidden">
                {/* Left Blur Overlay */}
                <div
                    className="absolute top-0 left-0 h-full w-32 bg-gradient-to-r from-[#6326FC] to-transparent z-10 pointer-events-none"
                    style={{ transform: 'translateX(-25%)' }}
                />

                {/* Right Blur Overlay */}
                <div
                    className="absolute top-0 right-0 h-full w-32 bg-gradient-to-l from-[#ff784b] to-transparent z-10 pointer-events-none"
                    style={{ transform: 'translateX(25%)' }}
                />

                <div className="flex animate-scroll gap-6">
                    {useCases.concat(useCases).map((useCase, index) => (
                        <div
                            key={index}
                            className="p-4 min-w-[280px] bg-white/80 border border-gray-200 rounded-lg shadow-md backdrop-blur-md text-center"
                        >
                            <FontAwesomeIcon icon={useCase.icon} className="text-4xl mb-4 text-[#6326FC]" />
                            <h3 className="text-lg font-semibold text-gray-800 mb-2">{useCase.title}</h3>
                            <p className="text-gray-600">{useCase.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <style jsx>{`
                .animate-scroll {
                    display: flex;
                    width: fit-content;
                    animation: scroll 60s linear infinite;
                }

                @keyframes scroll {
                    0% {
                        transform: translateX(0);
                    }
                    100% {
                        transform: translateX(-50%);
                    }
                }
            `}</style>
        </section>

    );
}

export default UseCases;
