// components/ScreenshotDisplay.js
import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'; // Ensure the CSS is imported for proper styling

function ScreenshotDisplay({ crop, setCrop, screenshotUrl, isLoading, apiResponseReceived, handleImageLoad }) {
    return (
        <div className="w-full mx-auto border border-gray-300 rounded shadow-lg overflow-auto h-96 relative font-normal">
            {!apiResponseReceived && !isLoading && (
                <p className="text-gray-600 mt-8 text-center">A screenshot of your website will appear here</p>
            )}
            {isLoading && (
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div className="w-9 h-9 border-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
                </div>
            )}
            {apiResponseReceived && screenshotUrl && (
                <ReactCrop
                    crop={crop}
                    onChange={(newCrop) => setCrop(newCrop)}
                    className="mx-auto"
                >
                    <img
                        src={screenshotUrl}
                        alt="Screenshot"
                        onLoad={handleImageLoad}
                        className="max-w-full max-h-full" // Make sure the image fits within the container
                    />
                </ReactCrop>
            )}
        </div>
    );
}

export default ScreenshotDisplay;
