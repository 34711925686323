import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import CreateJobModal from './CreateJobModal';
import JobDetailPanel from './JobDetailPanel';
import Banner from './Banner';
import ConfirmationModal from './ConfirmationModal';

function Dashboard({ user, handleLogout }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [jobCache, setJobCache] = useState({});
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedJob, setSelectedJob] = useState(null);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [jobToDeactivate, setJobToDeactivate] = useState(null);
    const [currentPlan, setCurrentPlan] = useState(null);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    useEffect(() => {
        const fetchUserPlan = async () => {
            const { data, error } = await supabase
                .from('users')
                .select('current_plan')
                .eq('id', user.id)
                .single();

            if (error) {
                console.error('Error fetching user plan:', error.message);
            } else {
                setCurrentPlan(data.current_plan);
            }
        };

        fetchUserPlan();
    }, [user.id]);

    useEffect(() => {
        const fetchJobs = async () => {
            const { data, error } = await supabase
                .from('monitoring_jobs')
                .select('*')
                .eq('user_id', user.id);

            if (error) {
                console.error('Error fetching jobs:', error.message);
            } else {
                setJobs(data);
            }
        };

        fetchJobs();
    }, [user.id]);

    const openConfirmModal = (job) => {
        setJobToDeactivate(job);
        setIsConfirmModalOpen(true);
    };

    const closeConfirmModal = () => {
        setIsConfirmModalOpen(false);
        setJobToDeactivate(null);
    };

    const fetchJobDetails = async (job) => {
        if (jobCache[job.id]) {
            setSelectedJob(jobCache[job.id]);
        } else {
            try {
                const [changesData, screenshotData] = await Promise.all([
                    supabase
                        .from('detected_changes')
                        .select('*')
                        .eq('monitoring_job_id', job.id)
                        .order('created_at', { ascending: false }),
                    supabase
                        .from('screenshots')
                        .select('*')
                        .eq('monitoring_job_id', job.id)
                        .order('taken_at', { ascending: true })
                        .limit(1),
                ]);

                const jobDetails = {
                    ...job,
                    changes: changesData.data || [],
                    originalScreenshot: screenshotData.data?.[0]?.screenshot_url || null,
                };

                setJobCache((prevCache) => ({ ...prevCache, [job.id]: jobDetails }));
                setSelectedJob(jobDetails);
            } catch (error) {
                console.error('Error fetching job details:', error);
            }
        }
    };

    const filteredJobs = jobs.filter(job =>
        job.url.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const activeJobs = filteredJobs.filter(job => job.is_active);
    const inactiveJobs = filteredJobs.filter(job => !job.is_active);

    return (
        <div className="h-screen w-full pt-14 bg-gray-100">
            <Banner user={user} handleLogout={handleLogout} />
            <div className="flex h-full w-full p-4 space-x-4 ">
                <div className="flex flex-col w-full lg:w-2/5 border border-gray-300 rounded-xl shadow-sm bg-white h-full overflow-hidden">
                    <header className="flex justify-between items-center h-14 px-5 py-3 bg-gradient-to-r from-[#6326FC] to-[#9541D0] text-white rounded-t-xl">
                        <h2 className="font-semibold text-lg">Dashboard</h2>
                    </header>
                    <div className="p-5 overflow-y-auto flex-1">
                        <div className="flex items-center mb-4">
                            <div className="relative w-full max-w-md">
                                <span className="absolute left-3 top-3 text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm5.707-4.293a1 1 0 00-1.414-1.414l-3.293 3.293a1 1 0 101.414 1.414l3.293-3.293z" clipRule="evenodd" />
                                    </svg>
                                </span>
                                <input
                                    type="text"
                                    placeholder="Search Jobs"
                                    className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:border-[#6326FC] focus:outline-none"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <button
                                onClick={openModal}
                                className="ml-3 p-2 text-sm font-semibold bg-gradient-to-r from-[#6326FC] to-pink-500 text-white rounded-lg shadow-lg hover:scale-105 transition-transform duration-200"
                            >
                                New Job
                            </button>
                        </div>

                        {isModalOpen && (
                            <CreateJobModal user={user} currentPlan={currentPlan} onClose={closeModal} />
                        )}

                        <div className="job-list mt-6">
                            <h3 className="text-lg font-semibold mb-2 text-[#6326FC]">
                                Active Jobs ({activeJobs.length})
                            </h3>
                            {activeJobs.length === 0 ? (
                                <p className="text-gray-600">No active monitoring jobs found.</p>
                            ) : (
                                <ul className="space-y-4">
                                    {activeJobs.map((job) => (
                                        <li
                                            key={job.id}
                                            className="p-4 hover:bg-[#6326FC]/10 rounded-md border border-gray-200 shadow-sm cursor-pointer"
                                            onClick={() => fetchJobDetails(job)}
                                        >
                                            <div className="flex justify-between items-center">
                                                <div className="flex-1 mr-4">
                                                    <p className="text-sm font-semibold text-gray-800 line-clamp-2">
                                                        <a
                                                            href={job.url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="inline text-ellipsis hover:text-[#6326FC]"
                                                            onClick={(e) => e.stopPropagation()}
                                                        >
                                                            {job.name || job.url}
                                                        </a>
                                                    </p>
                                                    <p className="text-xs text-gray-500">Frequency: {job.frequency}</p>
                                                </div>
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openConfirmModal(job);
                                                    }}
                                                    className="ml-3 p-2 text-sm font-semibold bg-gradient-to-r from-red-600 to-red-500 text-white rounded-lg shadow-lg hover:scale-105 transition-transform duration-200"
                                                >
                                                    Deactivate
                                                </button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        <div className="job-list mt-6">
                            <h3 className="text-lg font-semibold mb-2 text-[#6326FC]">
                                Inactive Jobs ({inactiveJobs.length})
                            </h3>
                            {inactiveJobs.length === 0 ? (
                                <p className="text-gray-600">No inactive monitoring jobs found.</p>
                            ) : (
                                <ul className="space-y-4">
                                    {inactiveJobs.map((job) => (
                                        <li
                                            key={job.id}
                                            className="p-4 bg-gray-100 hover:bg-gray-200 rounded-md border border-gray-200 shadow-sm cursor-pointer"
                                            onClick={() => fetchJobDetails(job)}
                                        >
                                            <div className="flex justify-between items-center">
                                                <div className="flex-1 mr-4">
                                                    <p className="text-sm font-semibold text-gray-800 line-clamp-2">
                                                        <a
                                                            href={job.url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="inline text-ellipsis hover:text-[#6326FC]"
                                                            onClick={(e) => e.stopPropagation()}
                                                        >
                                                            {job.name || job.url}
                                                        </a>
                                                    </p>
                                                    <p className="text-xs text-gray-500">Frequency: {job.frequency}</p>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col w-full lg:w-3/5 border border-gray-300 rounded-xl shadow-sm bg-white h-full overflow-hidden">
                    <header className="flex justify-between items-center h-14 px-5 py-3 bg-gradient-to-r from-[#9541D0] to-[#ff784b] text-white rounded-t-xl">
                        <h2 className="font-semibold text-lg">Job Details</h2>
                    </header>
                    <div className="flex-1 overflow-y-auto p-5">
                        {selectedJob ? (
                            <JobDetailPanel job={selectedJob} />
                        ) : (
                            <p className="flex items-center justify-center text-gray-400 h-full px-5">
                                Please select a job to view your job’s changes and checks
                            </p>
                        )}
                    </div>
                </div>
            </div>

            <ConfirmationModal
                isOpen={isConfirmModalOpen}
                jobId={jobToDeactivate?.id}
                onCancel={closeConfirmModal}
                message="Are you sure you want to remove this job?"
                onConfirmSuccess={(jobId) => {
                    setJobs((prevJobs) => prevJobs.filter((job) => job.id !== jobId));
                    setIsConfirmModalOpen(false);
                }}
            />
        </div>
    );
}

export default Dashboard;
