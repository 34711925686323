import React, { useCallback, useState } from 'react';
import ChangeViewModal from './ChangeViewModal';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

function JobDetailPanel({ job }) {
    const [selectedChange, setSelectedChange] = useState(null);
    const [hoveredChangeId, setHoveredChangeId] = useState(null);

    const formatDateInUTC = (dateString) => {
        const isoString = dateString.replace(' ', 'T') + 'Z'; // Convert to ISO format
        const dateInUTC = parseISO(isoString);
        return formatInTimeZone(dateInUTC, 'UTC', "yyyy-MM-dd, h:mm:ss a 'UTC'");
    };

    const formatRelativeTimeInUTC = (dateString) => {
        const isoString = dateString.replace(' ', 'T') + 'Z';
        const dateInUTC = parseISO(isoString);
        const nowInUTC = new Date(); // Current time in UTC
        return formatDistanceToNow(dateInUTC, { addSuffix: true, baseDate: nowInUTC });
    };

    const formatFrequency = useCallback((frequency) => {
        if (!frequency) return "Unknown";

        // Assuming frequency is in the format "HH:mm:ss"
        const [hours, minutes, seconds] = frequency.split(":").map(Number);
        let parts = [];

        if (hours) parts.push(`${hours} hour${hours > 1 ? "s" : ""}`);
        if (minutes) parts.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
        if (seconds) parts.push(`${seconds} second${seconds > 1 ? "s" : ""}`);

        return parts.length > 0 ? `Every ${parts.join(", ")}` : "Unknown";
    }, []);

    if (!job) {
        return null;
    }

    return (
        <div className="flex flex-col h-full w-full px-5 py-4 space-y-2 z-0">
            {/* Display the job name or URL */}
            <div className="rounded-lg text-center border bg-gray-50 border-gray-200 font-semibold py-2 px-4 mb-4">
                {job.name || job.url}
            </div>

            {/* Display the oldest screenshot if available */}
            {job.originalScreenshot ? (
                <div className="relative h-full w-full max-h-52 min-h-40 rounded-md border border-gray-300 overflow-hidden mb-4">
                    <img
                        src={job.originalScreenshot}
                        alt="Job Thumbnail"
                        className="w-full h-full object-contain"
                    />
                </div>
            ) : (
                <p className="text-gray-600">No screenshot available</p>
            )}
            {/* Display the formatted frequency */}
            <div className="flex items-center space-x-2 mb-2">
                <span className="text-gray-600 text-sm font-semibold">Frequency:</span>
                <p className="text-gray-800 text-sm">{formatFrequency(job.frequency)}</p>
            </div>

            {/* Display job status */}
            <div className="flex items-center space-x-2 mb-4">
                <span className="text-gray-600 text-sm font-semibold">Status:</span>
                <p className={`text-sm font-semibold ${job.is_active ? 'text-green-600' : 'text-red-500'}`}>
                    {job.is_active ? "Active" : "Inactive"}
                </p>
            </div>

            {/* Display changes detected header */}
            <h3 className="mt-4 mb-2 text-[#6326FC] text-lg font-semibold border-b-2 border-gray-200 pb-1">
                Changes Detected
            </h3>
            <div className="space-y-4">
                {job.changes && job.changes.length === 0 ? (
                    <p className="text-gray-600">No changes detected.</p>
                ) : (
                    job.changes.map((change) => (
                        <div
                            key={change.change_id}
                            className="flex flex-col md:flex-row items-center p-4 mb-4 border border-gray-200 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-300 bg-white"
                        >
                            {/* Left Section: Text Content */}
                            <div className="flex flex-col w-full md:w-3/4 px-4">
                                <div className="flex justify-between items-center mb-2">
                                    <span
                                        className="text-xs text-gray-500"
                                        onMouseEnter={() => setHoveredChangeId(change.change_id)}
                                        onMouseLeave={() => setHoveredChangeId(null)}
                                    >
                                        {hoveredChangeId === change.change_id
                                            ? formatDateInUTC(change.created_at)
                                            : formatRelativeTimeInUTC(change.created_at)}
                                    </span>
                                    {change.alert_sent && (
                                        <div className="flex items-center space-x-1 text-green-500">
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                className="w-4 h-4 fill-current"
                                            >
                                                <path
                                                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 15l-4-4 1.41-1.41L11 14.17l5.59-5.59L18 10l-7 7z"/>
                                            </svg>
                                            <span className="text-xs">Alert sent</span>
                                        </div>
                                    )}
                                </div>
                                <p className="text-sm text-gray-700 font-medium">
                                    An image or layout changed on the page.
                                </p>
                            </div>

                            {/* Right Section: Screenshot with Hover Overlay */}
                            <div
                                className="relative w-full md:w-1/4 h-24 md:h-20 mt-4 md:mt-0 overflow-hidden rounded-lg border border-gray-200 cursor-pointer hover:shadow-md transition-shadow duration-300"
                                onClick={() => setSelectedChange(change)}>
                                <img
                                    src={change.screenshot_url}
                                    alt="Change screenshot"
                                    className="w-full h-full object-cover"
                                />
                                {/* Hover overlay */}
                                <div
                                    className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-white text-sm font-semibold opacity-0 hover:opacity-100 transition-opacity duration-300"
                                >
                                    View Change
                                </div>
                            </div>
                        </div>

                    ))
                )}
            </div>

            {/* Render the ChangeViewModal when a change is selected */}
            {selectedChange && (
                <ChangeViewModal
                    newImageUrl={selectedChange.screenshot_url}
                    oldImageUrl={selectedChange.previous_screenshot_url}
                    onClose={() => setSelectedChange(null)}
                />
            )}
        </div>
    );
}

export default JobDetailPanel;
