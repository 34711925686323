import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faStar } from '@fortawesome/free-solid-svg-icons';

function Banner({ user, handleLogin, handleLogout, darkBackground }) {
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            if (user) {
                const { data, error } = await supabase
                    .from('users')
                    .select('checks_remaining, current_plan')
                    .eq('id', user.id)
                    .single();
                if (error) {
                    console.error('Error fetching user data:', error.message);
                } else {
                    setUserData(data);
                }
            }
        };

        fetchUserData();
    }, [user]);

    return (
        <div
            className={`w-full h-16 fixed top-0 flex items-center justify-between px-8 shadow-lg z-50 ${
                darkBackground ? 'bg-white/10 backdrop-blur-lg' : 'bg-white text-[#6326FC]'
            }`}
        >
            {/* Brand Logo and Name */}
            <div className="flex items-center space-x-3">
                <img
                    src={darkBackground ? '/webwatch_logo_white.svg' : '/webwatch_logo_purple.svg'}
                    alt="Webwatch Logo"
                    className="h-10 w-10"
                />
                <div>
                    <h1
                        className={`text-2xl font-extrabold tracking-tight bg-clip-text text-transparent ${
                            darkBackground
                                ? 'bg-gradient-to-r from-white to-gray-200'
                                : 'bg-gradient-to-r from-[#6326FC] to-[#ff784b]'
                        }`}
                    >
                        Webwatch
                    </h1>
                    <p
                        className={`text-xs font-medium ${
                            darkBackground ? 'text-white' : 'text-[#6326FC]'
                        }`}
                    >
                        Monitor with Confidence
                    </p>
                </div>
            </div>

            {/* User Info and Action Buttons */}
            <div className="flex items-center space-x-6">
                {user && userData && (
                    <div className="flex items-center space-x-4">
                        {/* Checks Remaining */}
                        <div
                            className={`flex items-center px-3 py-1 rounded-full ${
                                darkBackground ? 'bg-[#6326FC]/20 text-white' : 'bg-[#6326FC]/10 text-[#6326FC]'
                            }`}
                        >
                            <FontAwesomeIcon icon={faCheckCircle} className="text-lg mr-1"/>
                            <span className="text-sm font-semibold">Checks Remaining:</span>
                            <strong className="ml-1 text-lg">{userData.checks_remaining}</strong>
                        </div>

                        {/* Divider */}
                        <div
                            className={`h-6 border-l ${darkBackground ? 'border-white/50' : 'border-[#6326FC]/50'}`}
                        ></div>

                        {/* Current Plan */}
                        <div
                            className={`flex items-center px-3 py-1 rounded-full ${
                                darkBackground ? 'bg-[#ff784b]/20 text-white' : 'bg-[#ff784b]/10 text-[#6326FC]'
                            }`}
                        >
                            <FontAwesomeIcon icon={faStar} className="text-lg mr-1"/>
                            <span className="text-sm font-semibold">Current Plan:</span>
                            <strong className="ml-1 text-lg">{userData.current_plan}</strong>
                        </div>
                    </div>
                )}

                {/* Action Buttons */}
                {user && (
                    <Link
                        to="/pricing"
                        className="px-6 py-2 text-sm font-semibold bg-gradient-to-r from-[#6326FC] to-[#ff784b] text-white rounded-md shadow-lg hover:scale-105 transition-transform duration-200"
                    >
                        Upgrade Plan
                    </Link>
                )}
                {user ? (
                    <button
                        onClick={handleLogout}
                        className="px-6 py-2 text-sm font-semibold bg-gradient-to-r from-red-500 to-pink-500 text-white rounded-md shadow-lg hover:scale-105 transition-transform duration-200"
                    >
                        Log Out
                    </button>
                ) : (
                    <button
                        onClick={handleLogin}
                        className={`px-6 py-2 text-sm font-semibold rounded-md shadow-lg hover:scale-105 transition-transform duration-200 ${
                            darkBackground ? 'bg-white text-[#6326FC]' : 'bg-[#ff784b] text-white'
                        }`}
                    >
                        Log In
                    </button>
                )}
            </div>
        </div>
    );
}

export default Banner;
