import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Banner from './Banner';
import UrlInput from './UrlInput';
import ScreenshotDisplay from './ScreenshotDisplay';
import EmailFrequencyForm from './EmailFrequencyForm';
import UseCases from './UseCases';
import { supabase } from '../supabaseClient';

function LandingPage({ user, setUser }) {
    const [inputUrl, setInputUrl] = useState('');
    const [screenshotUrl, setScreenshotUrl] = useState('');
    const [apiResponseReceived, setApiResponseReceived] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [frequency, setFrequency] = useState('');
    const frequencies = ["Every two minutes", "Every five minutes", "Every fifteen minutes", "Every half hour", "Every hour", "Every 6 hours", "Daily", "Weekly", "Monthly"];
    const allowedFrequencies = ["Every fifteen minutes", "Every half hour", "Every hour", "Every 6 hours", "Daily", "Weekly", "Monthly"]
    const [isListboxVisible, setIsListboxVisible] = useState(false);
    const listboxRef = useRef(null);
    const [crop, setCrop] = useState({
        unit: 'px',
        width: 200,
        height: 200,
        x: 200,
        y: 200,
        aspect: 1
    });
    const [originalDimensions, setOriginalDimensions] = useState({ width: 0, height: 0 });
    const [displayedImageDimensions, setDisplayedImageDimensions] = useState({ width: 0, height: 0 });

    const navigate = useNavigate();

    const handleLogin = () => navigate('/login');
    const handleSignupRedirect = () => navigate('/signup');

    const handleLogout = async () => {
        await supabase.auth.signOut();
        setUser(null);
    };

    const toggleListboxVisibility = () => {
        setIsListboxVisible(!isListboxVisible);
    };

    const handleClickOutside = (event) => {
        if (listboxRef.current && !listboxRef.current.contains(event.target)) {
            setIsListboxVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleEmailChange = (event) => setEmail(event.target.value);
    const handleInputChange = (event) => {
        setInputUrl(event.target.value);
        setApiResponseReceived(false);
    };

    const handleImageLoad = (event) => {
        setOriginalDimensions({
            width: event.target.naturalWidth,
            height: event.target.naturalHeight,
        });

        setDisplayedImageDimensions({
            width: event.target.offsetWidth,
            height: event.target.offsetHeight,
        });
    };

    const handleGoClick = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/take-screenshot`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ url: inputUrl }),
            });

            if (response.ok) {
                const imageBlob = await response.blob();
                setScreenshotUrl(URL.createObjectURL(imageBlob));
                setApiResponseReceived(true);
            } else {
                throw new Error('Screenshot API responded with an error.');
            }
        } catch (error) {
            console.error('Error taking screenshot:', error);
            setApiResponseReceived(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleWatchButtonClick = async () => {
        if (originalDimensions.width === 0 || displayedImageDimensions.width === 0) {
            console.error("Image dimensions not set yet");
            return;
        }

        const scaleX = originalDimensions.width / displayedImageDimensions.width;
        const scaleY = originalDimensions.height / displayedImageDimensions.height;

        const adjustedCrop = {
            x: Math.round(crop.x * scaleX),
            y: Math.round(crop.y * scaleY),
            width: Math.round(crop.width * scaleX),
            height: Math.round(crop.height * scaleY)
        };

        const intervalMapping = {
            "Every two minutes": 0.1,
            "Every five minutes": 5,
            "Every fifteen minutes": 15,
            "Every half hour": 30,
            "Every hour": 60,
            "Every 6 hours": 360,
            "Daily": 1440,
            "Weekly": 10080,
            "Monthly": 43200
        };

        const intervalInMinutes = intervalMapping[frequency];
        if (!intervalInMinutes) {
            console.error('Invalid frequency selected');
            return;
        }

        const currentTime = new Date();
        const nextRunAt = new Date(currentTime.getTime() + intervalInMinutes * 60 * 1000);

        const requestBody = {
            url: inputUrl,
            contact_email: email,
            frequency: `${intervalInMinutes} minutes`,
            x: adjustedCrop.x,
            y: adjustedCrop.y,
            width: adjustedCrop.width,
            height: adjustedCrop.height,
            user_id: user.id,
            is_active: true,
            created_at: currentTime.toISOString(),
            next_run_at: nextRunAt.toISOString()
        };

        try {
            const { error } = await supabase.from('monitoring_jobs').insert([requestBody]);

            if (error) {
                throw new Error(`Error inserting job: ${error.message}`);
            }

            alert("Monitoring job created successfully!");
        } catch (error) {
            console.error('Error creating monitoring job:', error);
        }
    };

    return (
        <div
            className="flex flex-col items-center justify-start min-h-screen bg-gray-100 bg-gradient-to-r from-[#6326FC] to-[#ff784b]">
            <Banner user={user} handleLogin={handleLogin} handleLogout={handleLogout} darkBackground={true}/>

            {/* Hero Section */}
            <section
                className="relative w-full bg-gradient-to-r from-[#6326FC] to-[#ff784b] text-white py-32 text-center">
                <div className="max-w-4xl mx-auto px-6 relative z-20">
                    <h1 className="text-6xl font-extrabold mb-6">Effortless Website Monitoring</h1>
                    <p className="text-lg mb-8 max-w-lg mx-auto">
                        Stay on top of changes with real-time monitoring and notifications tailored for your needs.
                    </p>
                    <button
                        onClick={handleSignupRedirect}
                        className="bg-white text-blue-500 font-semibold py-3 px-10 rounded-full shadow-lg hover:bg-gray-100 transition duration-300"
                    >
                        Get Started for Free
                    </button>
                </div>

                <div
                    className="absolute top-0 left-0 w-full h-full opacity-20 bg-cover pointer-events-none"
                    style={{backgroundImage: 'url(/background-pattern.svg)'}}
                ></div>
            </section>

            {/* Enhanced Screenshot and URL Input Section */}
            <section
                className="relative flex flex-col items-center justify-center w-3/5 -mt-28 z-10 bg-white rounded-2xl shadow-2xl p-10 transform transition-all duration-500 hover:shadow-3xl">
                <div className="w-full space-y-6">
                    <UrlInput
                        inputUrl={inputUrl}
                        handleInputChange={handleInputChange}
                        handleGoClick={handleGoClick}
                        isUrlEntered={inputUrl.trim() !== ''}
                    />
                    <ScreenshotDisplay
                        crop={crop}
                        setCrop={setCrop}
                        screenshotUrl={screenshotUrl}
                        isLoading={isLoading}
                        apiResponseReceived={apiResponseReceived}
                        handleImageLoad={handleImageLoad}
                    />
                    {/*{isLoading && (*/}
                    {/*    <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">*/}
                    {/*        <div*/}
                    {/*            className="w-12 h-12 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    <EmailFrequencyForm
                        email={email}
                        handleEmailChange={handleEmailChange}
                        handleWatchButtonClick={handleWatchButtonClick}
                        isEmailEntered={email.trim() !== ''}
                        frequency={frequency}
                        frequencies={frequencies}
                        allowedFrequencies={allowedFrequencies}
                        toggleListboxVisibility={toggleListboxVisibility}
                        isListboxVisible={isListboxVisible}
                        setFrequency={setFrequency}
                        listboxRef={listboxRef}
                        isLandingPage={true}
                    />
                </div>
            </section>
            <UseCases/>
        </div>
    );
}

export default LandingPage;
